import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'assets/css/style.css'
import 'assets/js/flexible.js'
import 'assets/js/echarts-resize.js'
import Swiper from "swiper"
import 'swiper/dist/css/swiper.min.css'
import 'swiper/dist/js/swiper.min.js'
// Vue.use(Swiper)
// import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';
// Vue.use(ElementUI);
import * as echarts from "echarts";
Vue.prototype.$echarts = echarts;
import dataV from '@jiaminghi/data-view'
Vue.use(dataV)
import scroll from 'vue-seamless-scroll'
Vue.use(scroll)

Vue.config.productionTip = false
Vue.use(router);
new Vue({
	router,
	render: h => h(App),
}).$mount('#app')
